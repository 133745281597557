import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { FormattedMessage, useIntl } from "react-intl";
import { FloatTeaser } from "./floatTeaser";

export const NewsCarousel = () => {
  const intl = useIntl();

  const { news } = useStaticQuery(graphql`
    query {
      news: allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: { fields: { collection: { eq: "news" }, lang: { eq: "en" } } }
        limit: 3
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              slug
              teaser
              date(formatString: "DD.MM.YYYY")
              year: date(formatString: "YYYY")
            }
          }
        }
      }
    }
  `);
  return (
    <FloatTeaser>
      <div className="swiper-container" data-autoplay="5000">
        <div className="swiper-wrapper">
          {news.edges.map(({ node }) => (
            <div className="swiper-slide" key={node.id}>
              <h2 className="h4">{node.frontmatter.title}</h2>
              <p>{node.frontmatter.teaser}</p>
              <Link
                to={`${intl.formatMessage({ id: "nav.news.link" })}/${
                  node.frontmatter.year
                }/${node.frontmatter.slug}`}
                className="btn mt-1"
                aria-label={`${intl.formatMessage({ id: "more.text" })} ${
                  node.frontmatter.title
                }`}
              >
                <FormattedMessage id="more.text" />
              </Link>
            </div>
          ))}
        </div>
        <div
          className="swiper-pagination justify-content-end autoplay"
          style={{ marginTop: "-48px", float: "right" }}
        ></div>
      </div>
    </FloatTeaser>
  );
};
